/* ======================================================================== */
/* 24. footer */
/* ======================================================================== */
.footer__area-border-top
	border-top: 1px solid $borders-dark
.footer__area
	.widgettitle
		margin-top: 0
		margin-bottom: 0.5em
		&:after
			display: none

@media screen and (max-width: $md)
	.text-center
		.logo__wrapper-img
			margin-left: auto
			margin-right: auto
