/* ======================================================================== */
/* 101. paddings */
/* ======================================================================== */
@for $i from 0 through 6
	.p-#{$i}
		padding: #{$i}em
	.pt-#{$i}
		padding-top: #{$i}em
	.pr-#{$i}
		padding-right: #{$i}em
	.pb-#{$i}
		padding-bottom: #{$i}em
	.pl-#{$i}
		padding-left: #{$i}em
	.py-#{$i}
		padding-top: #{$i}em
		padding-bottom: #{$i}em
	.px-#{$i}
		padding-left: #{$i}em
		padding-right: #{$i}em
	@media screen and (min-width: $sm + 1)
		.p-sm-#{$i}
			padding: #{$i}em !important
		.pt-sm-#{$i}
			padding-top: #{$i}em !important
		.pr-sm-#{$i}
			padding-right: #{$i}em !important
		.pb-sm-#{$i}
			padding-bottom: #{$i}em !important
		.pl-sm-#{$i}
			padding-left: #{$i}em !important
		.py-sm-#{$i}
			padding-top: #{$i}em !important
			padding-bottom: #{$i}em !important
		.px-sm-#{$i}
			padding-left: #{$i}em !important
			padding-right: #{$i}em !important
	@media screen and (min-width: $md + 1)
		.p-md-#{$i}
			padding: #{$i}em !important
		.pt-md-#{$i}
			padding-top: #{$i}em !important
		.pr-md-#{$i}
			padding-right: #{$i}em !important
		.pb-md-#{$i}
			padding-bottom: #{$i}em !important
		.pl-md-#{$i}
			padding-left: #{$i}em !important
		.py-md-#{$i}
			padding-top: #{$i}em !important
			padding-bottom: #{$i}em !important
		.px-md-#{$i}
			padding-left: #{$i}em !important
			padding-right: #{$i}em !important
.pt-0-5
	padding-top: 0.5em
.pb-0-5
	padding-bottom: 0.5em
